<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 3.64798V15.5858C20 15.851 19.8965 16.1041 19.6919 16.2728C18.7444 17.0539 15.7125 19.1351 12 17.4147C9.58198 16.2943 7.45269 16.3779 6 16.6952L6 22H4V15.6549V3.99998V3.82807C4 3.49594 4.16337 3.18649 4.45759 3.03239C5.61766 2.4248 8.80883 1.14043 12 3.29691C14.5002 4.98644 16.5886 4.11543 18.0041 2.91504C18.6367 2.37853 20 2.81848 20 3.64798Z"
      :fill="fillColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'FlagFullIcon',
  props: {
    size: {
      type: [Number, String],
      default: 24,
    },
    fillColor: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
