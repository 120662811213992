<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 19V4H4V18H22V20H3H2V19ZM6 10.0855L10.2929 5.79265L10.9477 5.13782L11.6508 5.74053L14.5971 8.26609L21.5295 4H22V6.05879L15.0241 10.3517L14.4029 10.7339L13.8492 10.2592L11.0523 7.86169L6 12.914V10.0855ZM6 13.9458V16.3043L11.023 13.165L14.0039 14.8683L14.3322 15.056L14.7026 14.9793L22 13.4695V11.4272L14.6678 12.9442L11.4961 11.1318L10.977 10.8352L10.47 11.1521L6 13.9458Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: '#1F284D' },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
